<template></template>

<script>
import * as XLSX from "xlsx";

import whApi from "@/api/warehouse/";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

import numbro from "numbro";

export default {
  name: "app",
  props: {
    dataExportExcel: Object,
  },
  data() {
    return {
      jsonExport: [],

      mainProduct: [],
      subProduct: [],

      // loading: false
    };
  },

  async created() {
    await this.groupDateExport();
    // this.onExport();
  },

  methods: {
    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },

    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY - HH:mm:ss น.");

        return oldDate;
      } else {
        return "-";
      }
    },

    checkTransactionType(type, item) {
      if (type) {
        if (type.substring(0, 3) === "POS") {
          this.styleTitleName = "color: #7337EE; font-weight: bold;";
          return "ขาย POS";
        }

        if (type.substring(0, 3) === "800") {
          if (item.sales_type === "0") {
            return "ขายส่ง";
          }

          if (item.sales_type === "1") {
            return "ขายปลีก";
          }
        }

        if (type.substring(0, 3) === "ADD") {
          return "ปรับสินค้าเข้า (+)";
        }

        if (type.substring(0, 3) === "DEL") {
          return "ปรับสินค้าออก (-)";
        }

        if (type.substring(0, 3) === "TO0") {
          return "ย้ายสินค้าออก";
        }

        if (type.substring(0, 3) === "TI0") {
          return "ย้านสินค้าเข้า";
        }

        if (type.substring(0, 3) === "RO2") {
          return "ซื้อสินค้า";
        }

        if (type.substring(0, 3) === "NEW") {
          return "เพิ่มสินค้าใหม่";
        }
      }
    },

    checkReceived(item) {
      if (
        item.no.substring(0, 3) === "POS" ||
        item.no.substring(0, 3) === "800" ||
        item.no.substring(0, 3) === "DEL" ||
        item.no.substring(0, 3) === "TO0"
      ) {
        return "";
      } else {
        if (item.no.substring(0, 3) === "NEW") {
          return item.element.product_first_bring_qty;
        }
        if (item.no.substring(0, 3) === "RO2") {
          return item.element.item_amt_recieve;
        }
        if (
          item.no.substring(0, 3) !== "NEW" &&
          item.no.substring(0, 3) !== "RO2"
        ) {
          return numbro(item.element.item_amt).format({
            thousandSeparated: true,
          });
        }
      }
    },

    checkPayOff(item) {
      if (
        item.no.substring(0, 3) === "POS" ||
        item.no.substring(0, 3) === "800" ||
        item.no.substring(0, 3) === "DEL" ||
        item.no.substring(0, 3) === "TO0"
      ) {
        return numbro(item.element.item_amt).format({
          thousandSeparated: true,
        });
      } else {
        return "";
      }
    },

    groupDateExport() {
      this.dataExportExcel.forEach((element, index) => {
        this.jsonExport.push({
          วันเวลา: this.formatDate(element.createdAt),
          เลขที่เอกสาร: element.sales_no
            ? element.sales_no
            : element.point_of_sale_no
            ? element.point_of_sale_no
            : element.transfer_no
            ? element.transfer_no
            : element.cut_off_no
            ? element.cut_off_no
            : element.receive_no
            ? element.receive_no
            : element.product_new_code
            ? element.product_new_code
            : element.purchase_no,
          ชื่อรายการ: this.checkTransactionType(
            element.sales_no
              ? element.sales_no
              : element.point_of_sale_no
              ? element.point_of_sale_no
              : element.transfer_no
              ? element.transfer_no
              : element.cut_off_no
              ? element.cut_off_no
              : element.receive_no
              ? element.receive_no
              : element.product_new_code
              ? element.product_new_code
              : element.purchase_no,
            element
          ),
          รับเข้า: this.checkReceived(
            element.cut_off_no
              ? {
                  no: element.cut_off_no,
                  element: element.cutOffStockItems[0],
                }
              : element.receive_no
              ? {
                  no: element.receive_no,
                  element: element.purchaseOrderItems[0],
                }
              : element.sales_no
              ? {
                  no: element.sales_no,
                  element: element.salesOrderItems[0],
                }
              : element.point_of_sale_no
              ? {
                  no: element.point_of_sale_no,
                  element: element.pointOfSaleItems[0],
                }
              : element.transfer_no
              ? {
                  no: element.transfer_no,
                  element: element.transferProductItems[0],
                }
              : element.product_new_code
              ? { no: element.product_new_code, element: element }
              : ""
          ),
          จ่ายออก: this.checkPayOff(
            element.cut_off_no
              ? {
                  no: element.cut_off_no,
                  element: element.cutOffStockItems[0],
                }
              : element.receive_no
              ? {
                  no: element.receive_no,
                  element: element.purchaseOrderItems[0],
                }
              : element.sales_no
              ? {
                  no: element.sales_no,
                  element: element.salesOrderItems[0],
                }
              : element.point_of_sale_no
              ? {
                  no: element.point_of_sale_no,
                  element: element.pointOfSaleItems[0],
                }
              : element.transfer_no
              ? {
                  no: element.transfer_no,
                  element: element.transferProductItems[0],
                }
              : element.product_new_code
              ? { no: element.product_new_code, element: element }
              : ""
          ),
          คงเหลือ: numbro(
            element.product_new_code
              ? element.product_first_bring_qty
              : element.total_qty
          ).format({
            thousandSeparated: true,
          }),
        });
      });

      this.onExport();
    },

    onExport() {
      const date = dayjs(new Date()).format("YYYY-MM-DD-HHMM");

      const dataWS = XLSX.utils.json_to_sheet(this.jsonExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS, "ProductMovement1", true);
      XLSX.writeFile(wb, `ProductMovement-${date}.xlsx`);
    },
  },
};
</script>
