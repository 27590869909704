<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        Product Movement
      </h3>

      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light-primary btn-hover-scale"
          style="font-size: 14px; font-weight: bold; height: 40px"
          @click="gotoExportFile()"
        >
          <i style="font-size: 16px" class="bi bi-box-arrow-up"></i>
          Export
        </button>

        <DialogExportFile
          :dialogExportFile="dialogExportFile"
          @closeDialogExportFile="closeDialogExportFile"
          @selectedTypeExportFile="selectedTypeExportFile"
        />
      </div>
    </div>
    <div class="card-title row ms-6 me-5 mt-7">
      <!-- <label for="input1  form-label" class="col-sm-10">ค้นหาสินค้า</label> -->
      <div class="col-sm-3">
        <input
          v-model="searchProduct"
          @input="searchProduct ? (isValid = true) : (isValid = false)"
          type="text"
          class="form-control"
          id="input1"
        />
      </div>

      <div class="col-sm-3 mb-3">
        <div
          class="form-check form-check-inline form-check-custom form-check-solid mt-3"
          style="display: flex; justify-content: space-around"
        >
          <input
            v-model="searchBy"
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            value="code"
            id="flexRadioChecked"
            :checked="searchBy === 'code' ? true : false"
          />
          <label class="form-check-label me-4" for="flexRadioChecked"
            >Item no.</label
          >
          <input
            v-model="searchBy"
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            value="barcode"
            id="flexRadioChecked2"
            :checked="searchBy === 'barcode' ? true : false"
          />
          <label class="form-check-label" for="flexRadioChecked2"
            >Barcode</label
          >
        </div>
      </div>

      <div class="col-md-2">
        <button
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold; width: 100%"
          @click="search()"
        >
          ค้นหา
        </button>
      </div>
    </div>

    <div class="row ms-6 me-5 mt-5">
      <div class="col-12 col-sm-4">
        <label for="name"><span>Item No</span></label>
        <input v-model="form.code" :class="'form-control '" disabled />
      </div>
      <div class="col-12 col-sm-4 mt-5 mt-sm-0">
        <label for="name"><span>Item Name</span></label>
        <input v-model="form.name" :class="'form-control'" disabled />
      </div>
      <div class="col-12 col-sm-4 mt-5 mt-sm-0">
        <label for="name"><span>Barcode</span></label>
        <input v-model="form.barcode" :class="'form-control '" disabled />
      </div>
    </div>

    <div class="row ms-6 me-5 mt-5">
      <div class="col-12 col-sm-4">
        <label for="name"><span>ราคาขายปลีก</span></label>
        <input v-model="form.retailPrice" :class="'form-control'" disabled />
      </div>

      <div class="col-12 col-sm-2 mt-5 mt-sm-0">
        <label for="name"><span>On Hand</span></label>
        <input v-model="form.qty" :class="'form-control'" disabled />
      </div>
      <div class="col-12 col-sm-2 mt-5 mt-sm-0">
        <label for="name"><span>In Transit</span></label>
        <input v-model="form.inTransit" :class="'form-control'" disabled />
      </div>

      <div class="col-12 col-sm-2 mt-5 mt-sm-0">
        <label for="name"><span>Min</span></label>
        <input v-model="form.min" :class="'form-control'" disabled />
      </div>
      <div class="col-12 col-sm-2 mt-5 mt-sm-0">
        <label for="name"><span>Max</span></label>
        <input v-model="form.max" :class="'form-control'" disabled />
      </div>
    </div>

    <div class="card-body mt-5" style="padding-top: 3px">
      <div class="table-responsive">
        <table
          class="table align-middle table-hover table-row-bordered table-row-dashed gy-5"
        >
          <thead>
            <tr
              style="width: 100%"
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
            >
              <th class="text-center">วันเวลา</th>
              <th class="text-center">เลขที่เอกสาร</th>
              <th class="text-center">ชื่อรายการ</th>
              <th class="text-center">รับเข้า</th>
              <th class="text-center">จ่ายออก</th>
              <th class="text-center">คงเหลือ</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="11">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td class="text-center">{{ formatDate(item.createdAt) }} น.</td>

              <td class="fw-boldest text-center">
                {{
                  item.cut_off_no
                    ? item.cut_off_no
                    : item.receive_no
                    ? item.receive_no
                    : item.sales_no
                    ? item.sales_no
                    : item.point_of_sale_no
                    ? item.point_of_sale_no
                    : item.transfer_no
                    ? item.transfer_no
                    : item.product_new_code
                    ? item.product_new_code
                    : ""
                }}
              </td>

              <td
                class="fw-boldest text-center"
                :style="
                  setStyleTitleName(
                    item.sales_no
                      ? item.sales_no
                      : item.point_of_sale_no
                      ? item.point_of_sale_no
                      : item.transfer_no
                      ? item.transfer_no
                      : item.cut_off_no
                      ? item.cut_off_no
                      : item.receive_no
                      ? item.receive_no
                      : item.product_new_code
                      ? item.product_new_code
                      : item.purchase_no,
                    item
                  )
                "
              >
                {{
                  checkTransactionType(
                    item.sales_no
                      ? item.sales_no
                      : item.point_of_sale_no
                      ? item.point_of_sale_no
                      : item.transfer_no
                      ? item.transfer_no
                      : item.cut_off_no
                      ? item.cut_off_no
                      : item.receive_no
                      ? item.receive_no
                      : item.product_new_code
                      ? item.product_new_code
                      : item.purchase_no,
                    item
                  )
                }}
              </td>

              <td class="fw-boldest text-end">
                {{
                  checkReceived(
                    item.cut_off_no
                      ? { no: item.cut_off_no, item: item.cutOffStockItems[0] }
                      : item.receive_no
                      ? {
                          no: item.receive_no,
                          item: item.purchaseOrderItems[0],
                        }
                      : item.sales_no
                      ? { no: item.sales_no, item: item.salesOrderItems[0] }
                      : item.point_of_sale_no
                      ? {
                          no: item.point_of_sale_no,
                          item: item.pointOfSaleItems[0],
                        }
                      : item.transfer_no
                      ? {
                          no: item.transfer_no,
                          item: item.transferProductItems[0],
                        }
                      : item.product_new_code
                      ? { no: item.product_new_code, item: item }
                      : ""
                  )
                }}
              </td>

              <td class="fw-boldest text-end">
                {{
                  checkPayOff(
                    item.cut_off_no
                      ? { no: item.cut_off_no, item: item.cutOffStockItems[0] }
                      : item.receive_no
                      ? {
                          no: item.receive_no,
                          item: item.purchaseOrderItems[0],
                        }
                      : item.sales_no
                      ? { no: item.sales_no, item: item.salesOrderItems[0] }
                      : item.point_of_sale_no
                      ? {
                          no: item.point_of_sale_no,
                          item: item.pointOfSaleItems[0],
                        }
                      : item.transfer_no
                      ? {
                          no: item.transfer_no,
                          item: item.transferProductItems[0],
                        }
                      : item.product_new_code
                      ? { no: item.product_new_code, item: item }
                      : ""
                  )
                }}
              </td>

              <td class="fw-boldest text-end">
                {{
                  formatThousand(
                    item.product_new_code
                      ? item.product_first_bring_qty
                      : item.total_qty
                  )
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="isExcel">
      <ExportExcelFile :dataExportExcel="dataExportExcel" />
    </div>

    <div v-if="isPdf">
      <ExportPdfFile :dataExportPdf="dataExportPdf" />
    </div>

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";

import whApi from "@/api/warehouse/";
import reportApi from "@/api/report/";

import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import Search from "../../components/search.vue";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import numbro from "numbro";

import DialogExportFile from "./components/dialogExportFile.vue";
import ExportExcelFile from "./components/exportFileExcel.vue";
import ExportPdfFile from "./components/exportFilePdf.vue";

import { Mutations } from "@/store/enums/StoreEnums";
import { mapState } from "vuex";

export default {
  components: {
    DialogExportFile,
    ExportExcelFile,
    ExportPdfFile,
    Pagination,
    Search,
    Loader,
  },
  setup() {
    document.title = "BELUCA | Product Movement";
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    companyItems: [],

    typeReport: false,

    searchBy: "barcode",
    searchProduct: "",

    showing1: null,

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    searchInput1: [],
    allData: [],

    pdItems: [],
    category: [],

    dateFormat: "YYYY/MM/DD",

    isBinding: false,

    form: {
      code: "",
      name: "",
      barcode: "",
      retailPrice: "",
      qty: "",
      inTransit: "",
      min: "",
      max: "",
    },

    mainQty: 0,

    //export
    dialogImportExcel: false,

    dialogExportFile: false,

    dataExportExcel: [],
    isExcel: false,

    dataExportPdf: [],
    isPdf: false,

    isExport: false,
    loadingExport: false,
    indexExport: "",

    loadingExport1: false,
  }),

  watch: {
    searchBy(val) {
      console.log(val);
    },
  },

  computed: {
    ...mapState({
      stateLoading: (state) => state.MockItems.isLoading,
    }),
  },

  methods: {
    setDateToday() {
      this.searchInput = dayjs(new Date());
      this.searchInput1[0] = dayjs(new Date()).subtract(12, "month");
      this.searchInput1[1] = dayjs(new Date());
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },

    formatThousand(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
        });
        return newVal;
      }
    },

    formatDate(val) {
      if (val) {
        return moment(val).format("DD/MM/YYYY, HH:mm:ss");
      } else {
        return "";
      }
    },

    async search() {
      this.loading = true;
      this.$store.commit(Mutations.SET_LOADING, true);

      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      if (this.searchBy === "code") {
        await this.searchByCode(companyBranchId, companyId, this.searchProduct);
      }
      if (this.searchBy === "barcode") {
        await this.searchByBarcode(
          companyBranchId,
          companyId,
          this.searchProduct
        );
      }
    },

    async searchByCode(companyBranchId, companyId, productCode) {
      let responseData = [];

      try {
        responseData = await reportApi.product.searchWhSnapshotByMovement(
          {
            product_code: productCode,
          },
          {
            companyId: companyId,
            companyBranchId: companyBranchId,
          }
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.setDataToForm(responseData.data);
        this.setDataToTable(responseData.data);
        this.loading = false;
        setTimeout(() => {
          this.$store.commit(Mutations.SET_LOADING, false);
        }, 500);
      } else {
        this.loading = false;
        this.form = {};
        setTimeout(() => {
          this.$store.commit(Mutations.SET_LOADING, false);
        }, 500);
        Swal.fire({
          icon: "error",
          title: `ค้นหาไม่สำเร็จ !`,
          text: `ไม่พบสินค้า Item no. : ${productCode}`,
          confirmButtonText: "ตกลง",
        });
      }
    },
    async searchByBarcode(companyBranchId, companyId, productBarcode) {
      let responseData = [];
      try {
        responseData = await reportApi.product.searchWhSnapshotByMovement(
          {
            product_barcode: productBarcode,
          },
          {
            companyId: companyId,
            companyBranchId: companyBranchId,
          }
        );
      } catch (error) {
        console.log(error);
      }

      if (responseData.response_status === "SUCCESS") {
        this.setDataToForm(responseData.data);
        this.setDataToTable(responseData.data);
        this.loading = false;
        setTimeout(() => {
          this.$store.commit(Mutations.SET_LOADING, false);
        }, 500);
      } else {
        this.form = {};
        this.loading = false;
        setTimeout(() => {
          this.$store.commit(Mutations.SET_LOADING, false);
        }, 500);
        Swal.fire({
          icon: "error",
          title: `ค้นหาไม่สำเร็จ !`,
          text: `ไม่พบสินค้า Barcode : ${productBarcode}`,
          confirmButtonText: "ตกลง",
        });
      }
    },

    setDataToForm(data) {
      this.form = {
        ...this.form,
        code: data[0][0].product_code,
        name: data[0][0].product_name,
        barcode: data[0][0].product_barcode,
        retailPrice: data[0][0].product_retail_price,
        inTransit: data[0][0].product_in_transit,
        qty: data[0][0].product_bring_qty,
        min: data[0][0].product_min_stock,
        max: data[0][0].product_max_stock,
      };

      this.isBinding = true;
    },

    setDataToTable(data) {
      this.dataItemsShow = data[1];
      this.tableItems = data[1];

      this.dataExportExcel = data[1];
      this.dataExportPdf = data[1];
    },

    clearData() {
      this.form = {};

      this.dataItemsShow = [];
      this.tableItems = [];

      this.dataExportExcel = [];
      this.dataExportPdf = [];
    },

    checkTransactionType(type, item) {
      if (type.substring(0, 3) === "POS") {
        this.styleTitleName = "color: #7337EE; font-weight: bold;";
        return "ขาย POS";
      }

      if (type.substring(0, 3) === "800") {
        if (item.sales_type === "0") {
          return "ขายส่ง";
        }

        if (item.sales_type === "1") {
          return "ขายปลีก";
        }
      }

      if (type.substring(0, 3) === "ADD") {
        return "ปรับสินค้าเข้า (+)";
      }

      if (type.substring(0, 3) === "DEL") {
        return "ปรับสินค้าออก (-)";
      }

      if (type.substring(0, 3) === "TO0") {
        return "ย้ายสินค้าออก";
      }

      if (type.substring(0, 3) === "TI0") {
        return "ย้านสินค้าเข้า";
      }

      if (type.substring(0, 3) === "RO2") {
        return "ซื้อสินค้า";
      }

      if (type.substring(0, 3) === "NEW") {
        return "สร้างสินค้าใหม่ ยกยอดมา";
      }
    },

    setStyleTitleName(type, item) {
      if (type.substring(0, 3) === "POS") {
        return "color: #0BB7AF; font-weight: bold;";
      }

      if (type.substring(0, 3) === "800") {
        if (item.sales_type === "0") {
          return "color: YellowGreen; font-weight: bold;";
        }

        if (item.sales_type === "1") {
          return "color: green; font-weight: bold;";
        }
      }

      if (type.substring(0, 3) === "ADD") {
        return "color: #F64E60; font-weight: bold;";
      }

      if (type.substring(0, 3) === "DEL") {
        return "color: #F64E60; font-weight: bold;";
      }

      if (type.substring(0, 3) === "TO0") {
        return "color: #EE9D01; font-weight: bold;";
      }

      if (type.substring(0, 3) === "TI0") {
        return "color: #EE9D01; font-weight: bold;";
      }

      if (type.substring(0, 3) === "RO2") {
        return "color: #7337EE; font-weight: bold;";
      }

      if (type.substring(0, 3) === "NEW") {
        return "color: #3699ff; font-weight: bold;";
      }
    },

    checkReceived(item) {
      if (
        item.no.substring(0, 3) === "POS" ||
        item.no.substring(0, 3) === "800" ||
        item.no.substring(0, 3) === "DEL" ||
        item.no.substring(0, 3) === "TO0"
      ) {
        return "";
      } else {
        if (item.no.substring(0, 3) === "NEW") {
          return item.item.product_first_bring_qty;
        }
        if (item.no.substring(0, 3) === "RO2") {
          return item.item.item_amt_recieve;
        }
        if (
          item.no.substring(0, 3) !== "NEW" &&
          item.no.substring(0, 3) !== "RO2"
        ) {
          return numbro(item.item.item_amt).format({
            thousandSeparated: true,
          });
        }
      }
    },

    checkPayOff(item) {
      if (
        item.no.substring(0, 3) === "POS" ||
        item.no.substring(0, 3) === "800" ||
        item.no.substring(0, 3) === "DEL" ||
        item.no.substring(0, 3) === "TO0"
      ) {
        return numbro(item.item.item_amt).format({
          thousandSeparated: true,
        });
      } else {
        return "";
      }
    },

    //export file excel, pdf
    gotoExportFile() {
      this.dialogExportFile = true;
      this.isExcel = false;
      this.isPdf = false;
    },

    closeDialogExportFile() {
      this.dialogExportFile = false;
    },

    selectedTypeExportFile(val) {
      this.dialogExportFile = false;
      if (val === "excel") {
        this.isExcel = true;
      } else {
        this.isPdf = true;
      }
    },

    //export file excel, pdf
  },
};
</script>

<style scoped src="@/assets/custom-outside.css"></style>
