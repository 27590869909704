<template>
  <div></div>
</template>

<script>
import pdfMake from "pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "../../../../assets/build/vfs_fonts";

import apiConfig from "../../../../config/api";
import whApi from "@/api/warehouse/";

import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";
import { toFixed } from "ant-design-vue/lib/input-number/src/utils/MiniDecimal";

import { ArabicNumberToText } from "../../../../utils/numberToText";

import JsBarcode from "jsbarcode/bin/JsBarcode";

import moment from "moment";
import numbro from "numbro";

export default {
  props: {
    dataExportPdf: Object,
  },
  data: () => ({
    jsonExport: [],

    newDataTable: [],

    user: "",
    numberToTh: "",
  }),
  async created() {
    console.log(this.dataExportPdf, 123);
    await this.groupDateExport();
  },

  methods: {
    groupDateExport() {
      this.setDataToTable();
    },

    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },

    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY - HH:mm:ss น.");

        return oldDate;
      } else {
        return "-";
      }
    },

    checkTransactionType(type, item) {
      if (type.substring(0, 3) === "POS") {
        this.styleTitleName = "color: #7337EE; font-weight: bold;";
        return "ขาย POS";
      }

      if (type.substring(0, 3) === "800") {
        if (item.sales_type === "0") {
          return "ขายส่ง";
        }

        if (item.sales_type === "1") {
          return "ขายปลีก";
        }
      }

      if (type.substring(0, 3) === "ADD") {
        return "ปรับสินค้าเข้า (+)";
      }

      if (type.substring(0, 3) === "DEL") {
        return "ปรับสินค้าออก (-)";
      }

      if (type.substring(0, 3) === "TO0") {
        return "ย้ายสินค้าออก";
      }

      if (type.substring(0, 3) === "TI0") {
        return "ย้านสินค้าเข้า";
      }

      if (type.substring(0, 3) === "RO2") {
        return "ซื้อสินค้า";
      }

      if (type.substring(0, 3) === "NEW") {
        return "สร้างสินค้าใหม่ ยกยอดมา";
      }
    },

    checkReceived(item) {
      if (
        item.no.substring(0, 3) === "POS" ||
        item.no.substring(0, 3) === "800" ||
        item.no.substring(0, 3) === "DEL" ||
        item.no.substring(0, 3) === "TO0"
      ) {
        return "";
      } else {
        if (item.no.substring(0, 3) === "NEW") {
          return item.element.product_first_bring_qty;
        }
        if (item.no.substring(0, 3) === "RO2") {
          return item.element.item_amt_recieve;
        }
        if (
          item.no.substring(0, 3) !== "NEW" &&
          item.no.substring(0, 3) !== "RO2"
        ) {
          return numbro(item.element.item_amt).format({
            thousandSeparated: true,
          });
        }
      }
    },

    checkPayOff(item) {
      if (
        item.no.substring(0, 3) === "POS" ||
        item.no.substring(0, 3) === "800" ||
        item.no.substring(0, 3) === "DEL" ||
        item.no.substring(0, 3) === "TO0"
      ) {
        return numbro(item.element.item_amt).format({
          thousandSeparated: true,
        });
      } else {
        return "";
      }
    },

    exports() {
      const newWindow = window.open("", "_blank");

      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.fonts = {
        Roboto: {
          normal: "Kanit-Regular.ttf",
          bold: "Kanit-Medium.ttf",
          italics: "Kanit-Medium.ttf",
          bolditalics: "Kanit-Medium.ttf",
        },
      };

      const html = [
        {
          text: "Product Movement",
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 10],
        },

        // TABLE
        {
          table: {
            // width: "100%",
            widths: ["*", "*", "*", "*", "*", "*"],
            body: this.newDataTable,
          },
        },
      ];

      const pdfData = {
        content: html,
        pageOrientation: "landscape",
      };
      pdfMake.createPdf(pdfData).open({}, newWindow);
      this.$emit("generatedFile");
    },

    setDataToTable() {
      this.dataExportPdf.forEach((element, index) => {
        if (index === 0) {
          this.newDataTable.push(
            [
              {
                fontSize: 9,
                text: "วันเวลา",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "เลขที่เอกสาร",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "ชื่อรายการ",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "รับเข้า",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "จ่ายออก",
                alignment: "center",
              },
              {
                fontSize: 9,
                text: "คงเหลือ",
                alignment: "center",
              },
            ],
            [
              {
                text: this.formatDate(element.createdAt),
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "center",
              },
              {
                text: element.sales_no
                  ? element.sales_no
                  : element.point_of_sale_no
                  ? element.point_of_sale_no
                  : element.transfer_no
                  ? element.transfer_no
                  : element.cut_off_no
                  ? element.cut_off_no
                  : element.receive_no
                  ? element.receive_no
                  : element.product_new_code
                  ? element.product_new_code
                  : element.purchase_no,
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "center",
              },
              {
                text: this.checkTransactionType(
                  element.sales_no
                    ? element.sales_no
                    : element.point_of_sale_no
                    ? element.point_of_sale_no
                    : element.transfer_no
                    ? element.transfer_no
                    : element.cut_off_no
                    ? element.cut_off_no
                    : element.receive_no
                    ? element.receive_no
                    : element.product_new_code
                    ? element.product_new_code
                    : element.purchase_no,
                  element
                ),
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "center",
              },
              {
                text: this.checkReceived(
                  element.cut_off_no
                    ? {
                        no: element.cut_off_no,
                        element: element.cutOffStockItems[0],
                      }
                    : element.receive_no
                    ? {
                        no: element.receive_no,
                        element: element.purchaseOrderItems[0],
                      }
                    : element.sales_no
                    ? {
                        no: element.sales_no,
                        element: element.salesOrderItems[0],
                      }
                    : element.point_of_sale_no
                    ? {
                        no: element.point_of_sale_no,
                        element: element.pointOfSaleItems[0],
                      }
                    : element.transfer_no
                    ? {
                        no: element.transfer_no,
                        element: element.transferProductItems[0],
                      }
                    : element.product_new_code
                    ? { no: element.product_new_code, element: element }
                    : ""
                ),
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "right",
              },
              {
                text: this.checkPayOff(
                  element.cut_off_no
                    ? {
                        no: element.cut_off_no,
                        element: element.cutOffStockItems[0],
                      }
                    : element.receive_no
                    ? {
                        no: element.receive_no,
                        element: element.purchaseOrderItems[0],
                      }
                    : element.sales_no
                    ? {
                        no: element.sales_no,
                        element: element.salesOrderItems[0],
                      }
                    : element.point_of_sale_no
                    ? {
                        no: element.point_of_sale_no,
                        element: element.pointOfSaleItems[0],
                      }
                    : element.transfer_no
                    ? {
                        no: element.transfer_no,
                        element: element.transferProductItems[0],
                      }
                    : element.product_new_code
                    ? { no: element.product_new_code, element: element }
                    : ""
                ),
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "right",
              },
              {
                text: numbro(
                  element.product_new_code
                    ? element.product_first_bring_qty
                    : element.total_qty
                ).format({
                  thousandSeparated: true,
                }),
                fontSize: 9,
                fillColor: "#eaeced",
                alignment: "right",
              },
            ]
          );
        } else {
          this.newDataTable.push([
            {
              text: this.formatDate(element.createdAt),
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "center",
            },
            {
              text: element.sales_no
                ? element.sales_no
                : element.point_of_sale_no
                ? element.point_of_sale_no
                : element.transfer_no
                ? element.transfer_no
                : element.cut_off_no
                ? element.cut_off_no
                : element.receive_no
                ? element.receive_no
                : element.product_new_code
                ? element.product_new_code
                : element.purchase_no,
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "center",
            },
            {
              text: this.checkTransactionType(
                element.sales_no
                  ? element.sales_no
                  : element.point_of_sale_no
                  ? element.point_of_sale_no
                  : element.transfer_no
                  ? element.transfer_no
                  : element.cut_off_no
                  ? element.cut_off_no
                  : element.receive_no
                  ? element.receive_no
                  : element.product_new_code
                  ? element.product_new_code
                  : element.purchase_no,
                element
              ),
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "center",
            },
            {
              text: this.checkReceived(
                element.cut_off_no
                  ? {
                      no: element.cut_off_no,
                      element: element.cutOffStockItems[0],
                    }
                  : element.receive_no
                  ? {
                      no: element.receive_no,
                      element: element.purchaseOrderItems[0],
                    }
                  : element.sales_no
                  ? {
                      no: element.sales_no,
                      element: element.salesOrderItems[0],
                    }
                  : element.point_of_sale_no
                  ? {
                      no: element.point_of_sale_no,
                      element: element.pointOfSaleItems[0],
                    }
                  : element.transfer_no
                  ? {
                      no: element.transfer_no,
                      element: element.transferProductItems[0],
                    }
                  : element.product_new_code
                  ? { no: element.product_new_code, element: element }
                  : ""
              ),
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "right",
            },
            {
              text: this.checkPayOff(
                element.cut_off_no
                  ? {
                      no: element.cut_off_no,
                      element: element.cutOffStockItems[0],
                    }
                  : element.receive_no
                  ? {
                      no: element.receive_no,
                      element: element.purchaseOrderItems[0],
                    }
                  : element.sales_no
                  ? {
                      no: element.sales_no,
                      element: element.salesOrderItems[0],
                    }
                  : element.point_of_sale_no
                  ? {
                      no: element.point_of_sale_no,
                      element: element.pointOfSaleItems[0],
                    }
                  : element.transfer_no
                  ? {
                      no: element.transfer_no,
                      element: element.transferProductItems[0],
                    }
                  : element.product_new_code
                  ? { no: element.product_new_code, element: element }
                  : ""
              ),
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "right",
            },
            {
              text: numbro(
                element.product_new_code
                  ? element.product_first_bring_qty
                  : element.total_qty
              ).format({
                thousandSeparated: true,
              }),
              fontSize: 9,
              fillColor: index % 2 === 0 ? "#eaeced" : "",
              alignment: "right",
            },
          ]);
        }
      });

      this.exports();
    },
  },
};
</script>
